import pepe from "../assets/chair.png";
import PhotoGrid from "./Grid";

function Services() {
  return (
    <div className="max-w-[90%] mx-auto">
      <section className="flex flex-col justify-center items-center  mb-0 md:mb-32 max-w-4xl mx-auto">
        <h2 className="text-center text-8xl md:text-9xl font-medium mb-10">
          POLITIFI & MEME
        </h2>
        <p className="text-2xl font-light ">
          Dive into the fusion frenzy with $TREPE, where the world of Trump
          meets the iconic Pepe! With meme coin mania and altcoin season on the
          horizon, we're at the forefront with our exclusive blend. $TREPE
          #TrumpPepe #TREPE
        </p>
        <PhotoGrid />
      </section>
      <div className="relative max-w-5xl mx-auto text-white bg-blue-600 py-10 text-2xl font-black text-md text-center mb-32 rounded-md">
        ⭐️⭐️⭐️ <br className="block: md:hidden" /> Contract Renounced! Tax
        0/0, Liquidity locked <br className="block md:hidden" />
        ⭐️⭐️⭐️
      </div>
      <section className="flex flex-col justify-center items-center max-w-4xl mx-auto">
        <h2 className="text-center text-8xl md:text-9xl  font-medium mb-10">
          IT'S TRUMP, <br /> BUT IT'S PEPE
        </h2>
        <p className="text-2xl font-light max-w-3xl">
          Trump + PEPE it's that simple, a meme that stands the test of time,
          and team to send it where it is meant to be in the Solana ecosystem.
        </p>
        <img src={pepe} alt="Logo" className="mt-16 rounded-lg" />
      </section>
    </div>
  );
}
export default Services;
