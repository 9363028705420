import React, { useState } from "react";
import cloudsImage from "../assets/whitehouse.png";
import trump from "../assets/trump.png";
import plane from "../assets/plane.webm";
import smoke from "../assets/smoke.webm";
import { motion, AnimatePresence } from "framer-motion";
import { contract } from "../constants";

function Header() {
  const [copySuccess, setCopySuccess] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const handleCopyText = async () => {
    try {
      await navigator.clipboard.writeText(contract);
      setCopySuccess("Contract Copied!");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    } catch (err) {
      setCopySuccess("Failed to copy!");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    }
  };

  return (
    <header className="relative w-full flex flex-col justify-center items-center">
      <img
        src={cloudsImage}
        alt="clouds background"
        className="w-full h-[100vh] md:h-[100vh] max-h-[800px] md:max-h-none object-cover absolute top-0 left-0 -z-1 brightness-[85%]"
      />
      <motion.div
        className="relative h-fit flex justify-center items-center w-full z-10 overflow-x-hidden overflow-hidden"
        initial={{ y: "5%" }}
        whileInView={{ x: 0 }}
        transition={{ duration: 2, delay: 0 }}
        viewport={{ once: true }}
      >
        <img src={trump} className="w-[80%] mt-16 md:mt-0 max-w-lg" />
      </motion.div>

      <div className="flex flex-col w-full gap-4 text-center justify-center items-center text-white">
        <h1 className="text-5xl md:text-7xl font-black drop-shadow-2xl">
          $TREPE <br className="md:hidden" /> on SOL
        </h1>
        <h2 className="text-2xl md:text-4xl drop-shadow-2xl">
          MAKE AMERICA PEPE AGAIN
        </h2>
        <div className="flex flex-col z-10 justify-center items-center">
          <div className="relative">
            <button
              className="text-xl font-black bg-blue-600 py-3 px-12 my-3 rounded-full w-[100%] md:w-fit"
              onClick={handleCopyText}
            >
              Copy Contract 🇺🇸
            </button>
            <AnimatePresence>
              {showMessage && (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="absolute left-[0%] text-xl -translate-x-[-50%] w-full -top-16 bg-white text-blue-600 rounded-lg py-3 px-8 border-[2px] border-gray-200"
                >
                  {copySuccess}
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="w-[90%] max-w-lg grid grid-cols-2 gap-x-2">
            <a
              href="https://t.me/TREPEonSolana"
              target="_blank"
              rel="noopener noreferrer"
              className="text-xl font-black bg-white text-blue-600 py-3 px-12 rounded-full flex justify-center items-center w-full"
            >
              Telegram
              <span role="img" aria-label="message" className="ml-3">
                💬
              </span>
            </a>
            <a
              href="https://x.com/trumppepetrepe"
              target="_blank"
              rel="noopener noreferrer"
              className="text-xl font-black bg-red-600 text-white py-3 px-12 rounded-full flex justify-center items-center w-full"
            >
              Twitter
              <span
                role="img"
                aria-label="twitter"
                className="ml-3 -translate-y-[2px]"
              >
                🐥
              </span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
