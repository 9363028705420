import cat from "../assets/cat.png";
import { motion } from "framer-motion";

function About() {
  return (
    <div className="flex flex-col justify-center items-center max-w-2xl mx-auto">
      <div>
        <p className="text-lg py-6"></p>
        <p className="text-lg py-6">
          <span className="text-blue-600 font-black"></span>
          <span className="text-blue-600 font-black"></span>
        </p>
      </div>
      <div className="relative mt-16 overflow-visible">
        <motion.div
          className="absolute w-[80%] left-[10%] top-[-20%] translate-x-[-50%]"
          initial={{ x: "180%" }}
          whileInView={{ x: 0 }}
          transition={{ duration: 1, delay: 2 }}
          viewport={{ once: true }}
        ></motion.div>
      </div>
    </div>
  );
}
export default About;
