import About from "../components/About";
import Services from "../components/Services";
import eagle from "../assets/eagle.png";
import head from "../assets/trumphead.png";

function Main() {
  return (
    <>
      <main className="relative w-full mx-auto">
        <About />

        <img
          src={eagle}
          className="absolute max-h-[60vh] translate-y-[1300%] md:translate-y-[150%] z-0 opacity-10 grayscale"
        />
        <Services />
        <img
          src={head}
          className="absolute max-h-[10vh] -right-3 translate-y-[-800%] -z-10 -rotate-45"
        />
        {/* <Contact />  */}
      </main>
    </>
  );
}
export default Main;
