import React from "react";
import Image1 from "../assets/1.jpg";
import Image2 from "../assets/2.jpg";
import Image3 from "../assets/3.jpg";
import Image4 from "../assets/4.jpg";
import Image5 from "../assets/5.jpg";
import Image6 from "../assets/6.jpg";

const images = [
  { src: Image1, alt: "Description of image 1" },
  { src: Image2, alt: "Description of image 2" },
  { src: Image3, alt: "Description of image 3" },
  { src: Image4, alt: "Description of image 3" },
  { src: Image5, alt: "Description of image 3" },
  { src: Image6, alt: "Description of image 3" },
];

const PhotoGrid = () => {
  return (
    <div className="photo-grid my-10">
      {images.map((image, index) => (
        <img key={index} src={image.src} alt={image.alt} className="photo" />
      ))}
    </div>
  );
};

export default PhotoGrid;
