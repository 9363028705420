import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import Logo from "../assets/logo.png";

function Navbar() {
  const [navOpened, setNavOpened] = useState(false);
  const [showGoTop, setShowGoTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth < 768 && window.scrollY > 690) {
        setShowGoTop(true);
        setNavOpened(true);
      } else if (window.innerWidth >= 768 && window.scrollY > 220) {
        setShowGoTop(true);
        setNavOpened(true);
      } else {
        setShowGoTop(false);
        setNavOpened(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleBar = () => {
    setNavOpened(!navOpened);
  };

  return (
    <nav
      className={`navbar ${
        navOpened ? "navopened" : ""
      } absolute w-full top-0 z-50`}
    >
      <div className="container mx-auto px-4 py-2 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-white">
          <Link
            spy={true}
            smooth={true}
            duration={1000}
            to="headerbg"
            style={{ cursor: "pointer" }}
          >
            <img src={Logo} alt="logo" className="w-60 pt-4 drop-shadow-lg" />
          </Link>
        </h1>
        {/* <div className="button md:hidden" onClick={toggleBar}>
          <div className="burger bg-white w-6 h-1 mb-1"></div>
          <div className="burger bg-white w-6 h-1 mb-1"></div>
          <div className="burger bg-white w-6 h-1"></div>
        </div> */}
      </div>
    </nav>
  );
}

export default Navbar;
